import React from "react";

const Confirm = () => {
  return (
    <div>
      <h1>Thanks so much for signing up! There's one last step</h1>
      <p>
        Please check your inbox for an email that just got sent.
        You'll need to click the confirmation link to receive any
        further emails.
      </p>
      <p>
        If you don't see the email after a few minutes, you might
        check your spam folder or other filters and add
        team@kentcdodds.com to your contacts.
      </p>
      Thanks, Kent
    </div>
  );
};

export default Confirm;
